<template>
  <div class="animated fadeIn">

    <CModal ref="upload-file-modal" id="upload-file-modal"
      size="lg"
      v-model:visible="showModal"
      color="primary"
      @close="hideModal"
    >
    <!--   backdrop="static" -->
    <CModalHeader class="bg-primary text-light">
      <CModalTitle>{{ title }}</CModalTitle>
    </CModalHeader>

    <spinner-ttt :show="loading"></spinner-ttt>
    <CModalBody>

      <CRow v-if="editarArchivo && dataAsociarFile && dataAsociarFile.guid && !fileEliminado">
        <CCol sm="12">
          <div v-if="dataAsociarFile" class="files">
            <div class="file-item">
              <span>{{ dataAsociarFile.fichero }}</span>
              <span class="delete-file" @click="eliminarFicheroAsociado(dataAsociarFile.guid)">{{$t('GLOBAL.PACIENTES.MODAL.cambiar_fichero')}}
              </span>
            </div>
          </div>
        </CCol>
      </CRow>

      <CRow v-else>
        <CCol sm="12">
          <div v-if="state.files.length > 0" class="files">
            <div class="file-item" v-for="(file, index) in state.files" :key="index">
              <span>{{ file.name }}</span>
               <!-- TODO:  Confirmar subir otro archivo antes de asociarlo-->
              <span class="delete-file" @click="handleClickDeleteFile(index)">{{$t('GLOBAL.PACIENTES.MODAL.cambiar_fichero')}}</span>
            </div>
          </div>

          <div v-else class="dropzone" v-bind="getRootProps()">
            <div class="border-dropzone" :class="{ isDragActive }">
              <input v-bind="getInputProps()" />
              <p v-if="isDragActive">{{$t('GLOBAL.PACIENTES.MODAL.suelta_los_ficheros')}}</p>
              <p v-else class="mb-0">
                <CIcon name="cil-data-transfer-up" size="md" class="me-2 text-success"/>
                {{$t('GLOBAL.PACIENTES.MODAL.arrastra_pincha_selecciona')}}
              </p>

              <!-- <div v-if="isFocused" id="focus">focused</div>
              <div v-if="isDragReject" id="isDragReject">isDragReject: {{ isDragReject }}</div> -->
            </div>
          </div>
        </CCol>
      </CRow>

      <!-- form Asociar Archivo -->
      <CRow class="mt-4" v-if="dataAsociarFile">
        <Form as="CForm" ref="form" v-slot="{ errors }" @submit.prevent>
            <CRow class="mb-3">
              <CCol sm="3">
                <CFormLabel>{{$t('GLOBAL.PACIENTES.MODAL.bloqueado')}}</CFormLabel>
                <CFormSwitch
                  id="bloqueado" name="bloqueado"
                  v-model="dataAsociarFile.bloqueado"
                  size="xl"
                  @update:checked="checked => dataAsociarFile.bloqueado = checked"
                />
              </CCol>
              <CCol sm="3">
                <CFormLabel>{{$t('GLOBAL.PACIENTES.MODAL.visible')}}</CFormLabel>
                <CFormSwitch
                  id="visible" name="visible"
                  v-model="dataAsociarFile.visible"
                  size="xl"
                  @update:checked="checked => dataAsociarFile.visible = checked"
                />
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol sm="6">
                <CFormLabel class="required">{{$t('GLOBAL.PACIENTES.MODAL.nombre')}}</CFormLabel>
                <Field
                  as="CFormInput"
                  type="text"
                  id="name" name="nombre"
                  :rules="'required'"
                  :placeholder="$t('GLOBAL.FORMS.placeholder.add_nombre')"
                  v-model="dataAsociarFile.titulo"
                  :class="{ 'is-invalid': formError(errors, 'nombre')}"
                  v-bind:invalid-feedback="errors.name"
                />
                <small class="text-danger ml-2" v-if="formError(errors, 'nombre')">{{ errors.nombre }}</small>
              </CCol>

              <!--<CCol sm="6">
                <CFormLabel>{{$t('GLOBAL.PACIENTES.MODAL.categoria')}}</CFormLabel>
                <Field
                  as="CFormSelect"
                  id="categoria" name="categoria_id"
                  :options="categoriaList"
                  :rules="'required'"
                  :disabled="!categoriaList || categoriaList.length < 1"
                  v-model="dataAsociarFile.categoria_id"
                  :class="{ 'is-invalid': formError(errors, 'categoria_id')}"
                  v-bind:invalid-feedback="errors.categoria"
                >
                </Field>
                <small class="text-danger ml-2" v-if="formError(errors, 'categoria_id')">{{ errors.categoria_id }}</small>
              </CCol>-->

              <CCol sm="6">
                <CFormLabel>{{$t('GLOBAL.PACIENTES.MODAL.categoria')}}</CFormLabel>
                <Field
                  as="CFormSelect"
                  id="categoria" name="categoria_id"
                  :rules="'required'"
                  :disabled="!categoriaList || categoriaList.length < 1"
                  v-model="dataAsociarFile.categoria_id"
                  :class="{ 'is-invalid': formError(errors, 'categoria_id')}"
                  v-bind:invalid-feedback="errors.categoria"
                >
                  <!-- Opción por defecto -->
                  <option value="">{{$t('GLOBAL.FILTROS.seleccionar')}}</option>

                  <!-- Opciones del dropdown -->
                  <option v-for="(item) in categoriaList" :key="item.value" :value="item.value">
                    <!-- {{ t('GLOBAL.FICHEROS.modal.' + item.label, item.label) }} -->
                     {{ $tWithFallback('GLOBAL.FICHEROS.modal.' + item.label, item.label) }}
                    <!-- {{ t(`GLOBAL.currency.${item.abreviatura}`, item.abreviatura)}} -->
                  </option>
                </Field>
                <small class="text-danger ml-2" v-if="formError(errors, 'categoria_id')">{{ errors.categoria_id }}</small>
              </CCol>




            </CRow>

            <CRow>
              <CCol sm="12">
                <CFormTextarea
                  id="observaciones" name="observaciones"
                  :label="$t('GLOBAL.PACIENTES.MODAL.observaciones')"
                  :placeholder="$t('GLOBAL.PACIENTES.MODAL.introduce_un_texto')"
                  v-model="dataAsociarFile.observaciones"
                  rows="3"
                />
              </CCol>
            </CRow>

        </Form>
      </CRow>

      <!-- mostrar el archivo y opción descarga -->
      <CRow v-if="dataAsociarFile && dataAsociarFile.guid && !fileEliminado">
        <CCol md="12" class="mt-4">
          <div class="files-container" >
            <CCard class="form-group files-container-fila">
              <CCardBody>
                  <CListGroup flush>
                    <CListGroupItem disabled>
                      <strong>{{$t('GLOBAL.PACIENTES.MODAL.informacion')}}</strong>
                    </CListGroupItem>

                    <CListGroupItem class="mt-3 border-0">
                    <CRow>
                      <CCol sm="12" md="9" lg="9" class="fichero-detalle">
                        <p v-if="dataAsociarFile.ruta" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.ruta')}}</strong> {{ dataAsociarFile.ruta }}</p>
                        <p v-if="dataAsociarFile.name || dataAsociarFile.fichero" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.archivo')}}</strong> {{ dataAsociarFile.fichero ? dataAsociarFile.fichero : dataAsociarFile.name }}</p>
                        <!-- <p v-if="dataAsociarFile.peso" class="p-0"><strong>Peso:</strong> {{ convertSizeFileToMb(dataAsociarFile.peso) }} Mb</p> -->
                        <p v-if="dataAsociarFile.peso" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.peso')}}</strong> {{ formatBytes(dataAsociarFile.peso) }}</p>
                        <p v-if="dataAsociarFile.updated_at" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.edicion')}}</strong> <span v-if="dataAsociarFile.actualizado_por">{{ dataAsociarFile.actualizado_por}}, </span> {{ formatDateTimeCustom(dataAsociarFile.updated_at) }}</p>
                        <p v-if="dataAsociarFile.created_at" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.creacion')}}</strong> <span v-if="dataAsociarFile.creado_por">{{ dataAsociarFile.creado_por}}, </span> {{ formatDateTimeCustom(dataAsociarFile.created_at) }} </p>
                      </CCol>

                      <CCol col="12" md="3" lg="3" v-if="mimeType">
                        <div v-if="!['jpg', 'png', 'gif', 'jpeg', 'webp'].includes(mimeType.split('/')[1])"  class="files-container-preview">
                            <img ref="imagePreview" class="img-fluid" :src="setIcon(dataAsociarFile.mime)" alt=""  height="100px" />
                        </div>

                        <div v-else class="files-container-preview">
                            <img ref="imagePreview" class="img-fluid" :src="blobUrl" alt="" @load="loadedBlob"/>
                        </div>
                      </CCol>
                    </CRow>

                    </CListGroupItem>
                  </CListGroup>

              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>
      <CRow v-if="fileEliminado">
        <CCol md="12" class="mt-4">
          <div v-if="state.files.length > 0" class="files-container" >
            <CCard v-for="(item,  index) in state.files" class="form-group files-container-fila"
              :value="item" :key="index">
              <CCardBody>
                  <CListGroup flush>
                    <CListGroupItem disabled>
                      <strong>{{$t('GLOBAL.PACIENTES.MODAL.informacion')}}</strong>
                    </CListGroupItem>

                    <CListGroupItem class="mt-3 border-0">
                    <CRow>
                      <CCol sm="12" md="9" lg="9" class="fichero-detalle">
                        <p v-if="item.ruta" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.ruta')}}</strong> {{ item.ruta }}</p>
                        <p v-if="item.name || item.fichero" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.archivo')}}</strong> {{ item.fichero ? item.fichero : item.name }}</p>
                        <!-- <p v-if="item.size" class="p-0"><strong>Peso:</strong> {{ convertSizeFileToMb(item.size) }} Mb</p> -->
                        <p v-if="item.size" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.peso')}}</strong> {{ formatBytes(item.size) }}</p>
                        <p v-if="item.updated_at" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.edicion')}}</strong> {{ formatDateTimeCustom(item.updated_at, '-') }}</p>
                        <p v-if="item.created_at" class="p-0"><strong>{{$t('GLOBAL.PACIENTES.MODAL.creacion')}}</strong>  {{ formatDateTimeCustom(item.created_at, '-') }} </p>
                      </CCol>

                      <CCol col="12" md="3" lg="3">
                        <div class="files-container-preview">
                            <img class="img-fluid" :src="getImagen(item)" alt=""/>
                        </div>

                      </CCol>
                    </CRow>

                    </CListGroupItem>
                  </CListGroup>

              </CCardBody>
            </CCard>
          </div>
        </CCol>
      </CRow>

      <p class="text-danger" v-if="errorFile">
        <span>Error al subir el ficheros</span>
      </p>
    </CModalBody>
      <CModalFooter>

        <CButton v-if="fileEliminado" color="link" class="text-secondary">
        <CIcon name="cil-data-transfer-down" class="me-2 text-secondary" />  {{$t('GLOBAL.BTN.descargar')}} </CButton>

        <CButton v-else  @click="descargarArchivo" color="link" class="text-info">
         <CIcon name="cil-data-transfer-down" class="me-2 text-info" />    {{$t('GLOBAL.BTN.descargar')}}</CButton>

        <CButton @click="hideModal" color="link" class="text-secondary">  {{$t('GLOBAL.BTN.cancelar')}}</CButton>
        <CButton @click="updateFichero" color="primary" class="text-white" :disabled="errorFile || ( dataAsociarFile && !dataAsociarFile.guid)">  {{$t('GLOBAL.BTN.aceptar')}}</CButton>
      </CModalFooter>
    </CModal>
  </div>
  <toast ref="toast"></toast>
</template>
<script>

import { useDropzone } from 'vue3-dropzone';
import { reactive, watch } from 'vue';

import { subirFichero, fetchTiposFichero, downloadFichero } from '@/app/shared/global/services/fichero-service';
import { formError, createBase64, formatDateTimeCustom, convertSizeFileToMb,
formatBytes, mimeFilesIcons, getIconFile, blobToJson } from '@/app/shared/utils/tools';

// import vuei18nDefaultValue from "@/mixins/vuei18nDefaultValue";

export default {
  name: "EditarFicherosModal",
  // mixins: [vuei18nDefaultValue],
  setup(props) {

    const preFile = reactive({ guid: ''});
    const fileUploaded = reactive({ guid: ''});

    const filesPreview = reactive({
      files: [],
    });

    const state = reactive({
      files: [],
    });


    const options = reactive({
      multiple: false,
      onDrop,
      // accept: '.jpg',
      // acceptedTypes: props.acceptedTypes
      disabled: props.editarArchivo
    })

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isFocused,
      isDragReject,
      open,
      ...rest
    } = useDropzone(options)

    function onDrop(acceptFiles, rejectReasons) {
      // console.log(acceptFiles);
      // console.log(rejectReasons);
      state.files = acceptFiles;
      saveFiles(acceptFiles);
    }


    async function saveFiles(files) {

      let temps = [];

      for (const file of files) {
        let attachment = {};
        attachment.name = file.name;
        attachment.size = file.size;
        attachment.result = await createBase64(file);
        filesPreview.files.push(attachment.result);

        temps = [...temps, attachment];
      }

      let updateFile = null;
      const isFileNew = !!preFile.guid;

      const dataFile = temps.shift();
      if (isFileNew) updateFile = preFile.guid;

      subirFichero(props.modeloTipo, props.modeloId, dataFile, updateFile).then( resp => {

        if (resp.status !== 'success') throw resp.message
        fileUploaded.guid = resp.data;

      }).catch( e => {
        // TODO: notificar
        console.log(e);
      }).finally(() => console.log('ARCHIVO SUBIDO'));

    };

    function handleClickDeleteFile(index) {
      preFile.guid = fileUploaded.guid
      state.files.splice(index, 1);
      filesPreview.files = [];
    }

    watch(state, () => {
      console.log('state', state.files);
    });

    watch(isDragActive, () => {
      console.log('isDragActive', isDragActive.value, rest);

    });

    return {
      getRootProps,
      getInputProps,
      isDragActive,
      state,
      filesPreview,
      handleClickDeleteFile,
      fileUploaded,
      preFile,
      ...rest,
    };
  },
  props: {
    show: { type: Boolean, default: false, required: true },
    title: String,
    multiple: Boolean,
    acceptedTypes: Array,
    modeloId: { type: Number, required: true },
    modeloTipo: { type: String, required: true },
    fichero: { type: Object, required: true },
  },
  data() {
    return {
      successModal: false,
      errorFile: false,

      files: [],

      loading: false,
      categoriaList: [],
      editarArchivo: true,
      fileEliminado: false,
      blobUrl: null,
      mimeType: null
    }
  },
  emits: ['editarFichero', 'closed', 'descargarFichero'],
  beforeUnmount() {
    this.resetData()
  },
  mounted() {
    this.getCategorias();
  },
  computed: {
    showModal: {
      get: function () {
        return this.show;
      },
      set: function (newValue) {
        this.$emit("update:show", newValue);
      },
    },

    dataAsociarFile:{
      get: function () {
        return this.fichero;
      },
      set: function (newValue) {
        this.$emit("update:fichero", newValue);
      },
    },
  },
  watch: {
    dataAsociarFile: {
      inmediate: true,
      handler(data) {
        if (!data && !data?.guid) return

        this.mimeType = data.mime;
        // this.preFile.guid = data.guid;

        this.getFilePreview(data.guid).then(blob => {
          this.blobUrl = URL.createObjectURL(blob)
        })


      }
    }
  },
  methods: {
    loadedBlob(){
      if (this.blobUrl) URL.revokeObjectURL(this.blobUrl);
    },
    setIcon(mime) {
      const arrayMime = mime.split('/');

      if (mimeFilesIcons(arrayMime[1])) {
        return `icons-file/${arrayMime[1]}.svg`
      } else {
        return `icons-file/${getIconFile(arrayMime[0])}.svg`
      }
    },


    async getFilePreview(guid) {

      const resp = await downloadFichero(guid).catch(e => {
        console.log(e);
        this.$refs.toast.showToast(this.$t("GLOBAL.FICHEROS.error_descargar"), null, 'error', '2000');
      });
      return resp.data
    },
    resetData(file = false) {
      this.state.files = [];
      this.filesPreview.files = [];
      this.fileUploaded.guid = ''
      this.fileEliminado = file
      this.preFile.guid = ''
      this.blobUrl = null;
    },
    formatDateTimeCustom(date) {
      return formatDateTimeCustom(date);
    },

    hideModal(){
      this.resetData();
      this.$emit('closed', true);
      // this.$refs['upload-file-modal'].hide();
    },

    formError(errors, value) {
      return formError(errors, value);
    },

    getImagen(index){
      if (this.filesPreview.files.length > 0) {
        return this.filesPreview.files[0]
      }
    },

    getCategorias() {
      fetchTiposFichero(this.modeloTipo).then(async res => {
        const respuesta = res instanceof Blob ? await blobToJson(res) : res;

        respuesta.data.hijos.forEach(hijo => {
          this.categoriaList.push({
            value: hijo.id,
            label: hijo.nombre
        });
        });

        console.log('categoriaslist',this.categoriaList);
      }).catch(e => {
        console.log(e);
        this.$refs.toast.showToast(this.$t("GLOBAL.FICHEROS.error_obteniendo_categorias"), null, "error", "5000");
      })
    },

    updateFichero() {
      this.$refs.form.validate().then(v => {
        if(!v.valid) return

        if (this.fileEliminado) {
          this.dataAsociarFile.guid = JSON.parse(JSON.stringify(this.fileUploaded.guid))
        }

        this.$emit('editarFichero', {...this.dataAsociarFile});
        this.resetData()
      });
    },

    convertSizeFileToMb(fileSizeBits) {
      if (fileSizeBits)
        return convertSizeFileToMb(fileSizeBits)
    },
    formatBytes(bytes, decimals = 2) {
      return formatBytes(bytes, decimals = 2)
    },


    eliminarFicheroAsociado(guid) {
      this.resetData(true);
      this.preFile.guid = guid;
    },

    descargarArchivo() {

      if (this.fileEliminado) {
        this.dataAsociarFile.guid = JSON.parse(JSON.stringify(this.fileUploaded.guid))
      }

      this.$emit('descargarFichero', {...this.dataAsociarFile});
      // this.hideModal();
    },




  }
}
</script>

<style lang="scss">

.dropzone {
  width: 100%;
  // max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;


  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // row-gap: 16px;
  // border: 2px dashed #41b883;
  // background-color: #fff;
  // transition: 0.3s ease all;
  // label {
  //   padding: 8px 12px;
  //   color: #fff;
  //   background-color: #41b883;
  //   transition: 0.3s ease all;
  // }
  // input {
  //   display: none;
  // }


}
.files {
  width: 100%;
  // max-width: 300px;
  margin: 0 auto;
  padding: 10px;
  border-radius: 16px; //8
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  font-size: 12px;
  line-height: 1.5;
}

.border-dropzone {
  // border: 2px dashed #ccc;
  border: 2px dashed #41b883;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: all 0.3s ease;
  background: #fff;

  &.isDragActive {
    // border: 2px dashed #ffb300;
    border: 2px dashed #ffb300;
    background: rgb(255 167 18 / 20%);
  }
}

.file-item {
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(255 167 18 / 20%);
  padding: 7px;
  padding-left: 15px;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  .delete-file {
    background: rgba(var(--cui-primary-rgb), var(--cui-bg-opacity));
    color: #fff;
    padding: 5px 10px;
    border-radius: 8px;
    cursor: pointer;
  }
}


.fichero-detalle {
  p {
    margin: 0
  }
  line-height: 1.5em;
}


.img-preview {
  // width: 100%;
  // max-height: 150px;

  img {
    @media (min-width: 400px) {
      max-width: 150px;
      max-height: 150px;
    }
  }
}


</style>
