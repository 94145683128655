<template>
  <div class="contenedordots" v-if="show" :class="{ 'full-screen': fullScreen }">
   
    <div class="dots">
      <div></div>
      <div></div>
      <div></div>
      <!-- <div>
        <span class="text">{{ text }}</span>
      </div> -->
    </div>
   
    <p class="mb-0 text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: 'SpinnerDots',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    text: { type: String, default: '' }
  }
}
</script>

<style lang="scss" scoped>

.contenedordots {
  display: flex;
  justify-content: center;
  align-items:center;
  padding: 10px 0px 20px 0px;
  z-index: 999999;
}

.full-screen {
  position: fixed;
}

.text {
  color: #fc2f70;
  margin-left: 2rem;

}

.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #fc2f70;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}




</style>
