import { useI18n } from 'vue-i18n'

export default {
  install: (app) => {
    app.config.globalProperties.$tWithFallback = (str, fallbackStr) => {
      const { t, te } = useI18n()
      return t && te
        ? te(str)
          ? t(str)
          : fallbackStr ?? str
        : fallbackStr ?? str
    }
  }
}

/**
 * para usarlo, importar en main.js
 * 
 * import i18nHelpers from '@/plugins/i18nHelpers';
 * 
 * app.use(i18nHelpers);
 * 
 * 
 * 
 * 
 * en el componente dónde se quiera usar:
 * 
 *  {{ $tWithFallback('GLOBAL.FICHEROS.modal.' + item.label, item.label) }}
 * 
 */