<template>
  <!-- <SubirFicherosModal
    v-if="modeloId"
    v-model:show="subirModal"
    title="Fichero :: Nuevo"
    :multiple="false"
    :modeloId="modeloId"
    :modeloTipo="modeloTipo"
    :fileGuid="filePorAsociar.guid"
    :dataNewFile="filePorAsociar"
    :editarArchivo="Boolean(filePorAsociar.guid)"
    @added="(temps) => ficheroSubido(temps)"
    @closed="subirModal = false"
    @asociarFichero="(data) => asociarFichero(data)"
  /> -->

  <EditarFicherosModal
    v-if="modeloId"
    v-model:show="modalEditItem"
    :title="$t('GLOBAL.PACIENTES.MODAL.title_editar_fichero')"
    v-bind:fichero="ficheroToEdit"
    :multiple="false"
    :modeloId="modeloId"
    :modeloTipo="modeloTipo"
    @closed="modalEditItem = false"
    @editarFichero="(data) => editarFichero(data)"
    @descargarFichero="(data) => descargarFichero(data)"
  />


  <DeleteModal
    :show="modalDeleteItem"
    :title="$t('GLOBAL.FICHEROS.fichero')"
    :element="$t('GLOBAL.FICHEROS.fichero_selec')"
    @cancelled="modalDeleteItem=$event"
    @deleteItems="borrarFichero"
  >
  <p>{{$t('GLOBAL.FICHEROS.fichero')}}: <strong> {{getElement()}} </strong></p>
  </DeleteModal>
  <DeleteModal
    :show="modalDeleteItemMultiple"
    :title="$t('GLOBAL.FICHEROS.fichero')"
    :element="$t('GLOBAL.FICHEROS.fichero_selec')"
    @cancelled="modalDeleteItemMultiple=$event"
    @deleteItems="borrarFicheros"
  >
  <!-- <p>Fichero: <strong> {{getElement()}} </strong></p> -->
  </DeleteModal>


  <CCard class="mb-2">
    <spinner-ttt :show="loadingChild"></spinner-ttt>
    <CCardHeader
      class="pointer"
      @click="ficherosCollapsed = esDocumentacion ? ficherosCollapsed : !ficherosCollapsed"
    >
      <slot name="header">
        <span class="title-card-list text-dark">
          <CIcon name="cil-file" size="lg" class="me-2" />
          <strong>{{$t('GLOBAL.FICHEROS.ficheros')}} ({{ items.length }})</strong>
        </span>
      </slot>

      <div class="card-header-actions float-end">
        <template v-if="!esDocumentacion">
          <CIcon v-if="ficherosCollapsed" name="cil-chevron-bottom" />
          <CIcon v-else name="cil-chevron-top" />
        </template>

        <template v-else>
          <div class="dropdown">
            <CIcon
              class="dropdown-toggle"
              size="lg"
              name="cil-options"
              type="button"
              id="dropdownMenuButton2"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul v-if="esDocumentacion" class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
              <li @click="enviarEmailBienvenida">
                <span class="dropdown-item">
                  <CIcon name="cil-send" class="me-2 text-info" />
                  Enviar bienvenida
                </span>
              </li>
              <li><span class="dropdown-item" @click="descargarExcel()">
                        <font-awesome-icon :icon="['far', 'file-excel']" class="text-success me-2" />Descargar excel para contrato</span></li>
            </ul>
          </div>
        </template>


      </div>
    </CCardHeader>
    <spinner-ttt :show="loadingList"></spinner-ttt>
    <CCollapse :visible="!ficherosCollapsed" :duration="400">
      <!-- class="p-0" -->
      <CCardBody>
        <!-- @row-click="selectToEditModal" -->
        <CSmartTable
          v-if="loaded"
          class="listado-ficheros"
          v-model:items="items"
          :columns="camposFicheros"
          :tableProps="{
            hover: true,
            striped: true,
            responsive: configResponsive
          }"
          columnSorter
          selectable
          clickable-rows
          @row-click="selectToEditModal"
          :no-items-label="'Sin resultados'"
          @selected-items-change="items => itemsSeleccionados = items"
        >

          <!-- <template #bloqueado="{ item }">
            <td @click="blockFichero(item)">
              <CIcon v-if="item.bloqueado" name="cil-lock-locked" />
              <CIcon v-else name="cil-lock-unlocked" />
            </td>
          </template> -->
<!-- 
          <template #visible="{ item }">
            <td @click="visibleFichero(item)"> 
              <CIcon v-if="item.visible" name="cil-check" />
              <CIcon v-else name="cil-minus" />
            </td>
          </template> -->

          <template #titulo="{ item }">
            <td>
              <span class="titulo">{{ item.titulo }}</span>
            </td>
          </template>

          <template #created_at="{ item }">
            <td>
              {{ formatDateTimeCustom(item.created_at) }}
            </td>
          </template>

          <template #opciones="{ item }">
            <td class="px-0">
              <div class="dropdown">
                <CIcon
                  class="dropdown-toggle"
                  size="lg"
                  name="cil-options"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                />
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                  <!-- <li @click="modalEditar(item)">
                    <span class="dropdown-item">
                      <CIcon name="cil-pencil" class="me-2" /> Editar</span>
                  </li>        -->

                  <!-- <li v-if="!item.bloqueado" @click="blockFichero(item)">
                    <span class="dropdown-item">
                      <CIcon name="cil-lock-locked" class="me-2 text-warning" />
                      Bloquear
                      </span>
                  </li>
                  <li v-else @click="blockFichero(item)">
                    <span class="dropdown-item">
                      <CIcon name="cil-lock-unlocked" class="me-2 text-success" />
                      Desbloquear
                    </span>
                  </li> -->

                  <li @click="descargarFichero(item)">
                    <span class="dropdown-item">
                      <CIcon name="cil-data-transfer-down" class="me-2 text-info" /> {{$t('GLOBAL.BTN.descargar')}}</span>
                  </li>

                  <li @click="modalBorrar(item)">
                    <span class="dropdown-item">
                      <CIcon name="cil-trash" class="me-2 text-danger" /> {{$t('GLOBAL.BTN.borrar')}}</span>
                  </li>

                </ul>
              </div>
            </td>
          </template>
        </CSmartTable>

        <h5 class="text-center mt-4 mb-5" v-if="items.length < 1">
          {{$t('GLOBAL.LISTADOS.sin_resultados')}}
        </h5>

      </CCardBody>


    <CCardFooter class="white">
      <div class="d-flex align-items-center justify-content-end">

          <!-- Elige entre las etiquetas ya creadas o añade una nueva. -->
          <CButton
            :disabled="loading"
            type="submit" size="sm"
            class="send-button"
            color="primary"
            @click="abrirPerfilModal()"
            >
            {{$t('GLOBAL.BTN.subir_ficheros')}}
            </CButton>

            <div class="dropdown pt-1 ml-1">
            <CIcon
              class="dropdown-toggle"
              size="lg"
              name="cil-options"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            />
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <!-- <li @click="subirModal = true">
                <span class="dropdown-item">
                  <CIcon name="cil-file" class="me-2 text-info" />
                  Nuevo</span>
              </li> -->
              <li @click="descargarFicheros">
                <span class="dropdown-item">
                  <CIcon name="cil-data-transfer-down" class="me-2 text-info" />
                  {{$t('GLOBAL.BTN.descargar')}}
                </span>
              </li>
<!-- 
              <li @click="visibilidadFicheros(true)">
                <span class="dropdown-item">
                  <CIcon name="cil-check" class="me-2 text-success" />
                  Visible
                  </span>
              </li>
              <li @click="visibilidadFicheros(false)">
                <span class="dropdown-item">
                  <CIcon name="cil-minus" class="me-2 text-danger" />
                  No visible
                </span>
              </li>

              <li @click="bloquearFicheros(true)">
                <span class="dropdown-item">
                  <CIcon name="cil-lock-locked" class="me-2 text-warning" />
                  Bloquear
                  </span>
              </li>
              <li @click="bloquearFicheros(false)">
                <span class="dropdown-item">
                  <CIcon name="cil-lock-unlocked" class="me-2 text-success" />
                  Desbloquear
                </span>
              </li> -->

              <li @click="modalBorrarMultiple"><span class="dropdown-item">
                <CIcon name="cil-trash" class="me-2 text-danger"/>
                {{$t('GLOBAL.BTN.borrar')}}</span>
              </li>

            </ul>
          </div>
      </div>
    </CCardFooter>

    </CCollapse>
  </CCard>

  <toast ref="toast"></toast>
</template>

<script>
import { isProxy, toRaw } from 'vue';
import { asociarFichero, updateFichero, deleteFichero, downloadFichero, fetchAllFicheros } from '@/app/shared/global/services/fichero-service';
import SubirFicheroCard from '@/app/shared/components/cards/SubirFicheroCard';
import { formatDateTimeCustom } from '@/app/shared/utils/tools';
import { CONFIG_PANEL } from '@/config';
import { exportarExcel } from '../global/services/matricula-service';
import { resetResponseType } from '../global/services/global-service';

export default {
  name: 'CardListadoFicheros',
  components: { SubirFicheroCard },
  props: {
    loading: Boolean,
    loaded: Boolean,
    ficheros: {type: Array, default() {return []}, required: true },
    modeloTipo: { type: String, required: true},
    modeloId: { type: Number, required: true},
    modelCampos: { type: Object, default() { return {} }, required: true },
    resetData: {type:Boolean, default: false },
    esDocumentacion: {type:Boolean, default: false}
  },
  emits: ['create', 'getFicheros', 'update:ficheros', 'update:loading', 'updateLoading'],
  data() {
    return {
      camposFicheros: [
        // { key: 'check', label: '', sorter: false},
        // { key: 'bloqueado', label: '', sorter: false, _style: {width: '1%'}}, // { minWidth: '130px' },
        // { key: 'visible', label: '', sorter: false,  _style: {width: '1%'}},
        { key: 'titulo', label: this.$t('GLOBAL.FICHEROS.nombre'), sorter: true}, //
        { key: 'created_at', label:  this.$t('GLOBAL.FICHEROS.fecha_subida'), sorter: true },
        { key: 'opciones', label: '', sorter: false },
      ],

      /*

      'guid',
      'bloqueado',
      'visible',
      'restriccion_visibilidad',
      'categoria_id',
      'titulo'

      */
      ficherosCollapsed: this.esDocumentacion ? false : true,
      subirModal: false,
      modalDeleteItemMultiple: false,
      modalDeleteItem: false,
      modalEditItem: false,
      ficheroToEdit: null,
      // fileGuid: '',
      filePorAsociar: {
        guid: '',
        titulo: null,
        fichero: null,
      },
      editarArchivo: false,
      itemsSeleccionados: [],
      loadingChild: false,

      elemento: this.$tc('GLOBAL.ELEMENTOS.fichero', 1), //'Expediente',

    }
  },
  computed: {
    items: {
      get: function () { return [...this.ficheros]},
      set: function (newValue) { this.$emit("update:ficheros", newValue) },
    },
    loadingList: {
      get: function () {return this.loading},
      set: function (newValue) {this.$emit('update:loading', newValue)},
    },
    configResponsive() {
      return CONFIG_PANEL.responsive;
    }
  },
  methods: {
    abrirPerfilModal() {
      this.$emit('abrirPerfilModal', true); 
    },
    formatDateTimeCustom(date) {
      return formatDateTimeCustom(date);
    },
    normalizarFichero(nombre) {
      return (
        nombre.substring(0, nombre.lastIndexOf('-')) +
        nombre.substring(nombre.indexOf('.'))
      )
    },
    getElement() {
      if(this.ficheroToEdit) {
        return this.ficheroToEdit.titulo;
      }
    },

    selectToEditModal(item = null, index, column, e) {

      // INFO: errro smartTable columnas.
      if(!item || (item && !['bloqueado', 'visible', 'titulo', undefined].includes(column))) {
        if (item) {
          this.ficheroToEdit = { ...item};
          this.modalEditItem = true;
        }
      }
    },

    checkearSeleccionados() {
        // Reinicia todos los elementos a no seleccionados
        this.items.forEach(item => item._selected = false);

        // Itera sobre los elementos seleccionados y actualiza el estado correspondiente en items
        this.itemsSeleccionados.forEach(selectedItem => {
          const itemToUpdate = this.items.find(item => item.id === selectedItem.id);
          if (itemToUpdate) {
            itemToUpdate._selected = true;
          }
        });

        // Actualiza la referencia de items
        this.items = [...this.items];

        // Devuelve solo los elementos seleccionados
        return this.items.filter(item => item._selected === true);
      },

    // checkearSeleccionados() {
    //   this.items.map(item => item._selected = false);

    //   this.itemsSeleccionados.forEach(item => {
    //     this.items[this.items.indexOf(this.items.filter(i => i.id == item.id)[0])]._selected = item._selected;
    //   });

    //   this.items = [...this.items];

    //   return this.items.filter(item => item._selected === true);
    // },

    enviarEmailBienvenida() {
      let checked = this.checkearSeleccionados();

      if(!checked.length){
         this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.no_seleccionado_un_para_enviar",{elemento : this.elemento}),null, 'warn', '3000');

      }

      // TODO: modal y añadir a quien enviar el email en copia. persona relacionadas sede?
    },

    descargarFicheros(){
      let checked = this.checkearSeleccionados();

      if(!checked.length){
         this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.no_seleccionado_un_para_descargar",{elemento : this.elemento}),null, 'warn', '3000');

         return
      }

      // this.loadingList = true;
      this.$emit('updateLoading', true);
      if (isProxy(checked)) {
        checked = toRaw(checked);
      }
      let promises = [];
      checked.map((item) => {
        promises.push(this.descargarFichero(item))
      });

      Promise.all(promises)
      .then( (resp) => console.log(resp))
      .catch((err)=>{
        console.log(err);
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.descargar_error",{elemento : this.elemento}),null, 'error', '5000');

      })
      .finally(() => {
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.descargar_ok",{elemento : this.elemento}),null, 'success', '5000');

        // this.loadingList = false;
        this.$emit('updateLoading', false);
        // this.$emit('getFicheros', false);
      })

    },

    bloquearFicheros(bloqueado = true) {

      let checked = this.checkearSeleccionados();

      if(!checked.length){
        //this.$refs.toast.showToast(`No se ha seleccionado un archivo para ${bloqueado ? 'bloquear' : 'desbloquear'}.`, null, 'warn', '5000');
        this.$refs.toast.showToast(
        this.$t("GLOBAL.TOAST.archivo_no_seleccionado_para", { 
          accion: bloqueado ? 'bloquear' : 'desbloquear' 
        }),
        null,
        'warn',
        '5000'
      );

        return
      }

      // this.loadingList = true;
      this.$emit('updateLoading', true);
      if (isProxy(checked)) {
        checked = toRaw(checked);
      }
      let promises = [];
      checked.map((item) => {
        item.bloqueado = bloqueado
        promises.push(updateFichero({...item}))
      });

      Promise.all(promises)
      .then( (resp) => console.log(resp))
      .catch((err)=>{
        console.log(err);
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.editar_error",{elemento : this.elemento}),null, 'error', '5000');

      })
      .finally(() => {
        this.$emit('getFicheros', false);
        this.loadingList = false;
        this.$emit('updateLoading', false);

        //this.$refs.toast.showToast('Fichero ' + (!bloqueado ? `no bloqueado` : 'bloqueado'), null, 'success', '5000');

        this.$refs.toast.showToast(
          this.$t("GLOBAL.TOAST.fichero_bloqueado_no_bloqueado", { 
            estado: !bloqueado ? 'no bloqueado' : 'bloqueado'
          }),
          null,
          'success',
          '5000'
        );

      })
    },

    visibilidadFicheros(visible = true) {

      let checked = this.checkearSeleccionados();

      if(!checked.length){
        this.$refs.toast.showToast(`No se ha seleccionado un archivo para ${visible ? 'visible' : 'no visible'}.`, null, 'warn', '5000');
        return
      }

      this.loadingList = true;
      if (isProxy(checked)) {
        checked = toRaw(checked);
      }
      let promises = [];

      checked.map((item) => {
        item.visible = visible
        promises.push(updateFichero({...item}))
      });

      Promise.all(promises)
      .then( (resp) => console.log(resp))
      .catch((err)=>{
        console.log(err);
        this.$refs.toast.showToast(`Error al editar el archivo` ,null,"error","5000");
      })
      .finally(() => {
        this.$emit('getFicheros', false);
        this.loadingList = false;
        this.$refs.toast.showToast('Fichero ' + (!visible ? `no visible` : 'visible'), null, 'success', '5000');
      })

    },

    borrarFicheros() {
      let checked = this.checkearSeleccionados();

      if(!checked.length){
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.no_seleccionado_un_para_eliminar_error",{elemento : this.elemento}),null, 'warn', '5000');

        return
      }
      this.modalDeleteItemMultiple = false;
      this.loadingList = true;
      let promises = [];

      if (isProxy(checked)) {
        checked = toRaw(checked);
      }

      checked.map((item) => {
        promises.push(deleteFichero(item.id))
      });

      Promise.all(promises)
      .then( (resp) => console.log(resp))
      .catch((err)=>{
        console.log(err);
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.eliminar_error",{elemento : this.elemento}),null, 'error', '5000');

      })
      .finally(() => {
        this.$emit('getFicheros', false);
        this.loadingList = false;
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.eliminar_ok",{elemento : this.elemento}),null, 'success', '5000');

      })
    },

    errorUpload(data) {
      data.map( item => {
        this.$refs.toast.showToast(`${item}`, null, 'warn', '5000');
      })
    },

    nuevoFichero(data){
      this.filePorAsociar = data;
      this.editarArchivo = true;

      if (isProxy(data)) {
        this.filePorAsociar = toRaw(item);
      }

      this.subirModal = true;
    },
    resetNuevofichero() {
      // this.fileGuid = '',
      this.filePorAsociar = {
        guid: '',
        titulo: null,
        fichero: null,
      },
      this.editarArchivo = false
    },

    async asociarFichero(item) {

      this.resetNuevofichero();
      this.loadingList = true;
      this.subirModal = false;

      let dataFichero = { ...item};

      try {
        const resp = await asociarFichero(this.modeloTipo, this.modeloId, dataFichero)
        if (resp.status != 'success') throw resp.message

        this.items = [...this.items, resp.data];

        this.$refs.toast.showToast(`Fichero asociado`, null, 'success', '2000');
        this.$emit('getFicheros', true);
      }
      catch (err) {
        console.log(err);
        this.$refs.toast.showToast(`Error al asociar el fichero`, null, 'error', '5000');
      } finally {
        this.loadingList = false
        this.resetNuevofichero()
      }

    },
    modalBorrarMultiple() {
      let checked = this.checkearSeleccionados();

      if(!checked.length){
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.no_seleccionado_un_para_eliminar_error",{elemento : this.elemento}),null, 'warn', '5000');

        return
      }
      this.modalDeleteItemMultiple = true; 
    },
    modalBorrar(item) {
      this.modalDeleteItem = true;
      this.ficheroToEdit = {...item};
    },
    async borrarFichero() {

      // this.loadingList = true;
      this.modalDeleteItem = false;

      try {
        const resp = await deleteFichero(this.ficheroToEdit.id)
        console.log(resp,'borrar')
        if (resp.status != 'success') throw resp.message

        // this.items.splice(this.items.indexOf(this.items.filter(i => i.id == this.ficheroToEdit.id)[0]), 1);

        // this.items.splice(this.items.indexOf(this.ficheroToEdit), 1);
        // this.items = [...this.items];

        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.eliminar_ok",{elemento : this.elemento}),null, 'success', '5000');

        this.$emit('getFicheros', true);
        // this.getModelFicheros();

      }
      catch (err) {
        console.log(err)
        this.$refs.toast.showToast(`Error al eliminar el fichero`, null, 'error', '5000');
      } finally {
        // this.loadingList = false
        this.ficheroToEdit = null;
      }
    },

    ficheroSubido(item) {
      if (isProxy(item)) {
        const rawObject = toRaw(item);
        console.log(rawObject)
      }
    },

    modalEditar(item) {
      this.ficheroToEdit = {...item};
      if (isProxy(item)) {
        this.ficheroToEdit = toRaw(item);
      }
      this.modalEditItem = true;
    },
    async editarFichero(item) {
      if (!item) return

      let dataFichero = {...item};

      if (isProxy(item)) {
        dataFichero = toRaw(item);
      }

      // this.loadingChild = true;
      this.modalEditItem = false;

      try {
        const resp = await updateFichero(dataFichero)
        if (resp.status != 'success') throw resp.message

        this.items[this.items.indexOf(this.items.filter(p => p.id == item.id)[0])] = item;

        // update view
        // this.items = [...this.items];
        this.items = this.items.splice();

        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.editar_ok",{elemento : this.elemento}),null, 'success', '2000');

        this.$emit('getFicheros', false);
        this.ficheroToEdit = null;
      }
      catch (err) {
        console.log(err)
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.editar_error",{elemento : this.elemento}),null, 'error', '5000');

      } finally {
        // this.loadingChild = false
      }
    },

    blockFichero(item) {

      if (!item) return

      let dataFichero = {...item};

      if (isProxy(item)) {
        dataFichero = toRaw(item);
      }
      dataFichero.bloqueado = !dataFichero.bloqueado;

      this.updateGeneral({...dataFichero, bloqueado: dataFichero.bloqueado}, 'bloqueado');
    },

    visibleFichero(item) {

      if (!item) return

      let dataFichero = {...item};

      if (isProxy(item)) {
        dataFichero = toRaw(item);
      }
      dataFichero.visible = !dataFichero.visible;

      this.updateGeneral({...dataFichero, visible: dataFichero.visible}, 'visible');
    },

    async descargarFichero(item) {
      const resp = await downloadFichero(item.guid).catch(e => {
        console.log(e);
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.descargar_error",{elemento : this.elemento}),null, 'error', '2000');

      }).finally(() => {
        this.$refs.toast.showToast(this.$t("GLOBAL.TOAST.descargar_ok",{elemento : this.elemento}),null, 'success', '5000');


      });

      if (resp) { 

        this.forceFileDownload(resp, item.fichero);
      }
    },
    forceFileDownload(response, nombre){

      const url = window.URL.createObjectURL(response.data);  // createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');

      link.href = url;

      link.setAttribute('download', nombre);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    },

    updateGeneral(item, campo, notificar = true) {
      updateFichero({...item})
        .then( (resp) => {

          if (resp.status != 'success') throw resp.message;

          if (notificar) {
            this.$refs.toast.showToast('Fichero ' + (!item[campo] ? `no ${campo}` : campo), null, 'success', '5000');
            this.$emit('getFicheros', false);
          }

        })
        .catch( e => {
          console.log(e);
          this.$refs.toast.showToast('No se ha podido ' + (!item[campo] ? `deshabilitar ${campo}` : `habilitar ${campo}`) + ` del fichero`, null, 'error', '5000');
          item[campo] = !item[campo];
        });
    },

    async getModelFicheros() {
      console.log('get ficheros')

      const errorMessage = "Error obteniendo los ficheros de la empresa"

      fetchAllFicheros(this.modeloTipo, this.modeloId).then(resp => {
        if(resp.status == 'success') {
          this.$nextTick(() => {
            this.items = resp.data?.length > 0 ? [...resp.data] : [];
          });

        } else {
          this.$refs.toast.showToast(errorMessage, null, "error", "5000");
        }
      }).catch(e => {
        console.log(e);
        this.$refs.toast.showToast(errorMessage, null, "error", "5000");
      }).finally(() => {

      })
    },


    descargarExcel() {
      resetResponseType();
      exportarExcel(this.modeloId).then(res => {
        // this.forceFileDownload(res, `excel-contrato-${this.modeloId}`)
      })
    },

    // forceFileDownload(response, name){

    //   const url = window.URL.createObjectURL(new Blob([response.data]));

    //   const link = document.createElement('a');

    //   link.href = url;

    //   link.setAttribute('download', name + '.xlsx' );
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },

  },
}
</script>

<style lang="scss" scoped>


.card-footer {

  &.white{
    background-color: #ffffff;
    border-top: 0.5px solid var(--cui-border-color-translucent);
  }

  &.new-comment {
    background-color: #ffffff;
    border-top: 2px solid var(--cui-border-color-translucent);
    padding-top: 2rem;
  }

  // padding: var(--cui-card-cap-padding-y) var(--cui-card-cap-padding-x);
  // color: var(--cui-card-cap-color);
  // background-color: var(--cui-card-cap-bg);
  // border-top: var(--cui-card-border-width) solid var(--cui-card-border-color);
}

.listado-ficheros {

  & .titulo {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;

    line-clamp: 1;
    -webkit-line-clamp: 1;
  }

}
</style>
