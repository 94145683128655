<template>
    <div class="animated fadeIn">
      <CModal v-model:visible="showModal" size="lg" @close="cerrarModal">
  
        <CModalHeader class="bg-primary text-light">
          <CModalTitle>{{$t('GLOBAL.LESIONES.MODAL.nueva_lesion')}}</CModalTitle>
        </CModalHeader>
  
        <spinner-ttt :show="loading"></spinner-ttt>
        <CModalBody>
          <Form as="CForm" ref="form" @submit.prevent class="mt-2" v-slot="{ errors }">
            <CRow>
                <CCol class="mb-3" sm="6">
                    <CFormLabel class="required">{{$t('GLOBAL.LESIONES.MODAL.zona_lesion')}}</CFormLabel>
                    <Field
                    as="CFormInput"
                    type="text"
                    id="titulo" name="titulo"
                    :placeholder="$t('GLOBAL.FORMS.placeholder.add_zona')"
                    v-model="lesion.titulo"
                    :rules="'required'"
                    :class="{ 'is-invalid': formError(errors, 'titulo')}"
                    v-bind:invalid-feedback="errors.titulo"
                    />
                    <small class="text-danger ml-2" v-if="formError(errors, 'titulo')">{{ errors.titulo }}</small>
                </CCol>

                <CCol sm="6">
                    <label class="mb-2 required" for="categoria">{{$t('GLOBAL.LESIONES.MODAL.categoria')}}</label>
                    <Field
                        as="CFormSelect" 
                        id="categoria" name="categoria" 
                        :rules="'required'"
                        v-model="lesion.categoria_id"
                        :class="{ 'is-invalid': formError(errors, 'categoria')}"
                        v-bind:invalid-feedback="errors.categoria"
                        >
                      <option value="">{{$t('GLOBAL.BTN.seleccionar')}}</option>
                        <option v-for="(item) in categorias" :key="item.id" :value="item.value">
                          {{ $t(`GLOBAL.LESIONES.categorias.${item.label}`) }}
                        </option>
                      </Field>
                      <small class="text-danger ml-2" v-if="formError(errors, 'categoria')">{{ errors.categoria }}</small> 

                </CCol>
                <!-- <CCol sm="6">
                    <CFormLabel class="mb-2 required" for="categoria_id">Categoría</CFormLabel>
                    <CFormSelect
                        class="mb-3"
                        :rules="'required'"
                        id="categoria_id" name="categoria_id"
                        v-model="lesion.categoria_id"
                        v-bind:invalid-feedback="errors.categoria_id"
                        :class="{ 'is-invalid': formError(errors, 'categoria_id')}"

                    >
                        <option  value="" >
                            Seleccionar
                        </option>
                        <option v-for="(item) in categorias" :value="item.value">
                            {{ item.label }}
                        </option>
                    </CFormSelect>
                    <small class="text-danger ml-2" v-if="formError(errors, 'categoria_id')">{{ errors.categoria_id }}</small>

                </CCol> -->
                </CRow>
                <CRow>
                <CCol sm="12">
                    <CFormTextarea
                    id="descripcion" name="descripcion"
                    :label="$t('GLOBAL.LESIONES.MODAL.info_lesion')"
                    :placeholder="$t('GLOBAL.FORMS.placeholder.add_info')"
                    v-model="lesion.observaciones"
                    rows="3"
                    />
                </CCol>
            </CRow>
            <!-- <CRow>
              <CCol sm="12" class="mt-2">
                <CFormLabel >Imágenes</CFormLabel>
                <DragDropInput
                  size="8388608"
                  multiple
                  :maxFiles="4"
                  @saveFile="saveFile($event)"
                  @erroFile="disabled = true"
                />
              </CCol>
            </CRow> -->
  
          </Form>
        </CModalBody>
  
         <CModalFooter>
          <CButton @click="cerrarModal" class="text-secondary" color="link">{{$t('GLOBAL.BTN.cancelar')}}</CButton>
          <CButton class="text-light" @click="crearLesion" color="primary">
            <!-- <CIcon name="cil-check-circle" />  -->
            {{$t('GLOBAL.BTN.crear')}}
          </CButton>
         </CModalFooter>
  
      </CModal>
    </div>
    <toast ref="toast"></toast>
  </template>
  
  <script>  
  import { LESION_FIELDS, CATEGORIAS_LESION } from '@/config';
  import { formError, setStoreData } from '@/app/shared/utils/tools';
  import { fetchTypologyWithSons } from '@/app/shared/global/services/tipologia-service';
  
  export default {
    name: 'NuevaLesionModal',
    props: {
      show: { type: Boolean, default: false, required: true },
    },
    computed: {
      showModal() {
        return this.show;
      },
    },
    data() {
      return {
        listadoTipos: [],
        lesion: {...LESION_FIELDS, },
        loading: false,
        categorias: []
        // categorias: CATEGORIAS_LESION
      }
    },
    mounted() {
      fetchTypologyWithSons('categorias-de-lesiones').then(res => {
            if (res.status != "success") return;
            this.categorias = res.data.hijos.map((item) => ({ label: item.nombre, value: item.id }));
            //console.log(' this.categorias', this.categorias)
        }).catch(e => {
            this.$refs.toast.showToast('Error obteniendo las estados', null, 'error', '5000');
        })
    },
    methods: {
      crearLesion() {
        this.$refs.form.validate().then(v => {
          console.log(v, 'valido');
          if(!v.valid) return
          //this.loading = true;
          this.$emit('crearLesion', this.lesion);
        })
      },
  
      formError(errors, value) {
        return formError(errors, value);
      },
  
      cerrarModal() {
        this.loading = false;
        this.lesion = {...LESION_FIELDS};
        this.$emit('cancelled',false);
      }
    }
  }
  </script>
  
  <style>
  
  </style>
  