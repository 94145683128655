import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.pacientes;
const URLCENTRO = apiGlobalConstants.centro;
const URLAdmin = apiGlobalConstants.admin;
const pacienteUrl = apiGlobalConstants.paciente

const fetchAllPacientes = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};

const getPermisos = (PacienteId) => {
  return httpClient.get(`${URL}/permisos/${PacienteId}`).then(res => res);
};

const getEntidades = () => {
  return httpClient.get(`${URL}/entidades`).then(res => res);
}

const setPermisos = (entidades, PacienteId) => {
  return httpClient.put(`${URL}/permisos/${PacienteId}`, {entidades}).then(res => res);
};

const fetchOnePaciente = (PacienteId) => {
  return httpClient.get(`${URL}/${PacienteId}`).then(res => res.data);
};

const updatePaciente = (PacienteToEdit) => {
  return httpClient.put(`${URL}/${PacienteToEdit.id}`, PacienteToEdit).then(res => res.data);
};

const createPaciente = (data) => {
  return httpClient.post(`${URL}`, data).then(res => res.data);
};

const deletePaciente = (PacienteId) => {
  return httpClient.delete(`${URL}/${PacienteId}`).then(res => res);
};

const suggestResponsables = (type, modeloId, tipoSuggest, term = "") => {
  return httpClient.get(`${URLAdmin}/${type}/suggest-${tipoSuggest}/${modeloId}?term=${term}`).then(res => res.data);
};
const suggestPacientes = (term = "", subido_consentimiento_informado) => {
  let filter
  if(subido_consentimiento_informado){
    filter = `subido_consentimiento_informado=1`; 
  }else{ 
    filter = `nombre=${term}`;
  }
  return httpClient.get(`${URL}/suggest?${filter}`).then(res => res.data);
};

const asociarFicheroPaciente = (PacienteToEdit) => {
  return httpClient.put(`${URLCENTRO}/paciente/asociar-fichero/${PacienteToEdit.id}`, PacienteToEdit).then(res => res.data);
};
const subirFicheroPaciente = (data) => {
  return httpClient.post(`${URLCENTRO}/paciente/subir-fichero/${data.id}`).then(res => res.data);
};
const ficherosPaciente = (id) => {
  return httpClient.get(`${URLCENTRO}/paciente/ficheros/${id}`).then(res => res.data);
};
const tiposFicherosPaciente = () => {
  return httpClient.get(`${URLCENTRO}/paciente/tipos-fichero`).then(res => res.data);
};


const getQrConsentimiento = (paciente_id) => {
  return httpClient.put(`${URLCENTRO}/paciente/solicitar-consentimiento`, {paciente_id}).then(res => res.data);
};

//  api/paciente/comprobar-solicitud-consentimiento 
const checkTokenConsentimiento = (token) => {
  return httpClient.get(`${pacienteUrl}/comprobar-solicitud-consentimiento?token=${token}`).then(res => res.data);
};

// POST  api/paciente/subir-firma .... paciente.set-firma › Api\Centros\PacientesController@setFirma
const setFirmapaciente = (data) => {
  return httpClient.post(`${pacienteUrl}/subir-firma`, data).then(res => res.data);
}

export {
  tiposFicherosPaciente,
  ficherosPaciente,
  subirFicheroPaciente,
  asociarFicheroPaciente,
  fetchAllPacientes,
  fetchOnePaciente,
  updatePaciente,
  deletePaciente,
  createPaciente,
  getPermisos,
  getEntidades,
  setPermisos,
  suggestPacientes,
  suggestResponsables,
  getQrConsentimiento,
  checkTokenConsentimiento,
  setFirmapaciente
}
