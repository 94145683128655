import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import $store from '@/store';

//import { getPermisos } from '@/app/shared/global/services/admin-service';

import { getLoggedUser, refresh } from '@/app/views/auth/shared/services';

import DefaultLayout from '@/app/layouts/DefaultLayout'

import { centrosRoutes } from '@/app/views/centros';
import { pacientesRoutes } from '@/app/views/pacientes';
import { expedientesRoutes } from '@/app/views/expedientes';
import { authRoutes } from '@/app/views/auth';
import { homeRoutes } from '@/app/views/home';
import { saldoRoutes } from '@/app/views/saldo';
import { manualRoutes } from '@/app/views/manual';
import { publicaRoutes } from '@/app/views/publica';
import { registerRoutes } from '@/app/views/register';


const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      ...homeRoutes,
      ...pacientesRoutes,
      ...expedientesRoutes,
      ...centrosRoutes,
      ...saldoRoutes,
      ...manualRoutes,
    ],
  },
  ...publicaRoutes,
  ...authRoutes,
  ...registerRoutes,
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  linkActiveClass: 'open active',
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})


let checkValid = async () =>
  await getLoggedUser()
    .then(response => response)
    .catch(e => e);


router.beforeEach(async (to, from, next) => {

  let token = localStorage.getItem("token");
  let user,
    expired = false;

  if (token) {
    let response = await checkValid();

    if (response.status === 'success') {

      const storageLoginTime = localStorage.getItem('login_time');
      // let storageExpiresIn = localStorage.getItem('expires_in');
      // 3600 segundos = 60 minutos = 1 hora

      let now = new Date();

      let dateExpiraSoon = new Date(storageLoginTime);

      let dateExpira = new Date(storageLoginTime);

      dateExpira.setHours(dateExpira.getHours() + 1);
      dateExpiraSoon.setMinutes(dateExpiraSoon.getMinutes() + 40);

      if (now >= dateExpiraSoon && now <= dateExpira) {
        // token próximo a caducar
        await refresh();
      }

      // token válido
      user = response.data;

    } else {
      localStorage.removeItem("token");
      token = null;
      expired = true;
    }
  }
  // console.log(token,to.meta , to.meta.auth,'ACAAA')

  if (to.meta && to.meta.auth && !token) { // Verificar si la ruta requiere autenticación y el usuario no está autenticado
    next('/login'); // Redirigir al inicio de sesión
  } else {
    next(); // Continuar navegación
  }
  // to.matched.some(record => record.meta.auth
  // if (to.meta && to.meta.auth == true) {
  //   if (token) {
  //     to.query.user = user;
  //     return await tienePermisos(to, from, user) ? next() : next(from);
  //   } else {
  //     next({
  //       path: "/login",
  //       query: {
  //         expired: expired,
  //         redirect: to.fullPath
  //       }
  //     });
  //   }
  // } else if (to.meta && to.meta.auth == false) {
  //   if (token) {
  //     next({
  //       path: "/",
  //       query: { user: user, reditect: to.fullPath}
  //     });
  //   } else {
  //     return await tienePermisos(to, from, user) ? next() : next(from);
  //   }
  // } else {
  //   return await tienePermisos(to, from, user) ? next() : next(from);
  // }
});

// let tienePermisos = async (to, from, user) => {
//   if(!to.meta || !to.meta.entidad)
//     return true;

//   const recarga = from.path == '/' && from.redirectedFrom === undefined;

//   const permisos = !recarga ? $store.state.permisos.permisos : await (await getPermisos(user.id)).data.data.permisos;

//   const tienePermiso = permisos.includes(to.meta.entidad);


//   return tienePermiso;
// } 

export default router
