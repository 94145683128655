<template>
  <div class="locale-changer">
  <!-- {{ availableLanguages }} -->

 <CCol sm="12" class="d-flex align-items-center me-2 justify-content-between">
  <CFormSelect
    id="idioma" name="idioma"
    v-model="idiomaActual"
    @change="changeLanguage($event)"
  >
    <option :value="null" disabled>- {{ $t('GLOBAL.FILTROS.seleccionar') }} -</option>
    <option v-for="(item, index) in langs" :key="index" :value="item"> 
      {{ $t('GLOBAL.languages.' + item) }}
    </option>
  </CFormSelect>
</CCol>




  </div>
</template>

<script>

/*
Component vs. root scope

⚠️ Changing $i18n.locale inside a component does not update the root locale. 
If you rely on the root locale, for example when using root fallbacks, 
use $root.$i18n.locale instead of $i18n.locale.

*/
import { localize, setLocale } from "@vee-validate/i18n";
import { setIdioma } from '@/app/shared/global/services/centros-service';


export default {
  name: 'locale-changer',
  props: {
    useService: {
      type: Boolean,
      default: false, 
    },
  },
  data() {
    return {
      langs: [
        'pt', 'es',
      ],
      idiomaActual: localStorage.getItem('lang') ? localStorage.getItem('lang') : this.$i18n.locale,
      selectedLanguage: this.$i18n.locale, // Idioma actual
      // idiomaActual: this.$i18n.locale,
    }
  },
  methods: {
    changeLanguage(event) {

      const lang = event.target.value;

      if (this.useService) {
        setIdioma(lang); // Llamar al servicio si se pasa el prop
      }

      //setIdioma(lang); // Llamada al servicio

      // this.$i18n.locale = lang; // Cambiar el idioma dinámicamente
      this.$root.$i18n.locale = lang;
      localStorage.setItem('lang', lang); // Guardar en localStorage

      setLocale(lang);
    },




  },
  computed: {
    availableLanguages() {
      return this.$i18n.availableLocales; // Obtiene los idiomas configurados
    },
  },
  // watch: {
  //   'idiomaActual': function (val) {
  //     console.log('watch: ', val)
  //     this.$root.$i18n.locale = val; 
  //     localStorage.setItem('lang', val);   
  //   },
  // },
}
</script>


<style lang="scss">
.locale-changer {
  display: flex;
  justify-content:space-between;
}
</style>