import { httpClient } from '@/app/shared/http-service';
import { apiGlobalConstants } from '../config';

const URL = apiGlobalConstants.centros;
const URLCentro = apiGlobalConstants.centro;
const URLAdmin = apiGlobalConstants.centros;

const fetchAllCentros = (page = null, filtro = '') => {
  let filter = filtro.length > 0 ? filtro : "";

  if (page != null) {
    return httpClient.get(`${URL}?page=${page}&${filter}`).then(res => res.data)
  }
  else {
    return httpClient.get(`${URL}?${filter}`).then(res => res.data)
  }
};


const fetchOneCentro = (adminId) => {
  return httpClient.get(`${URL}/${adminId}`).then(res => res.data);
};

const updateCentroDatos = (adminToEdit) => {
  return httpClient.put(`${URL}/actualizar-datos-acceso/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};
const updateCentro = (adminToEdit) => {
  return httpClient.put(`${URL}/${adminToEdit.id}`, adminToEdit).then(res => res.data);
};

const createCentro = (data) => {
  return httpClient.post(`${URLCentro}/crear`, data).then(res => res.data);
};

const deleteCentro = (adminId) => {
  return httpClient.delete(`${URL}/${adminId}`).then(res => res);
};

const suggestCentros = (term = "") => {
  let filter = `nombre=${term}`;
  return httpClient.get(`${URL}/suggest?${filter}`).then(res => res.data);
};

const crearDireccion = (centroId, direccion) => {
  return httpClient.post(`${URL}/crear-direccion/${centroId}`, direccion).then(res => res.data);
}



const setIdioma = (idiomaSeleccionado) => {
  return httpClient.post(`${URLCentro}/centro/set-idioma`, {
    language: idiomaSeleccionado
  }).then(res => {
    if (res.data?.data?.access_token) {
      localStorage.setItem('token', res.data.data.access_token);
    }
    return res.data;
  });
};

// const setIdioma = (idiomaSeleccionado) => {
//   return httpClient.post(`${URLCentro}/centro/set-idioma`, {
//     language: idiomaSeleccionado
//   }).then(res => res.data);
// };

export {
  fetchAllCentros,
  fetchOneCentro,
  updateCentro,
  deleteCentro,
  createCentro,
  updateCentroDatos,
  suggestCentros,
  crearDireccion,
  setIdioma
}
